var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-sm-8 py-4",
          attrs: { color: "light_gray", tile: "", elevation: "0" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _c(
                  "span",
                  { staticClass: "text-sm-h5 text-body-1 font-weight-bold" },
                  [_vm._v(_vm._s(_vm.$t("label.preOrder")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-8", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "9" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "7", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-sm-6 pa-4 py-6 rounded-lg",
                          attrs: {
                            color: "transparent",
                            tile: "",
                            elevation: "5",
                          },
                        },
                        [
                          _c("div", { staticClass: "mb-6" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-bold mb-2 text-sm-body-1 text-body-2",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("label.yourBillingAddress"))
                                ),
                              ]
                            ),
                            _vm.billingAddress != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2",
                                    on: {
                                      click: _vm.openChangeBillingAddressDialog,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          justify: "space-between",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "10" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.billingAddress
                                                    .display_name
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.billingAddress.phone)
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.billingAddress.address)
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "auto" } },
                                          [
                                            _c("v-icon", {
                                              staticClass:
                                                "stroke--unbox_green",
                                              domProps: {
                                                textContent:
                                                  _vm._s("$arrowRightIcon"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2",
                                    on: { click: _vm.openAddAddressDialog },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          justify: "space-between",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "10" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("label.addAddress")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "auto" } },
                                          [
                                            _c("v-icon", {
                                              staticClass:
                                                "stroke--unbox_green",
                                              domProps: {
                                                textContent:
                                                  _vm._s("$plusCircleIcon"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                            _vm.isBillingAddressError
                              ? _c(
                                  "div",
                                  { staticClass: "red--text text-body-2 mb-2" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("label.invalidBillingAddress")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mb-6" },
                            [
                              _c(
                                "div",
                                { staticClass: "font-weight-bold mb-2" },
                                [_vm._v(_vm._s(_vm.$t("label.yourItems")))]
                              ),
                              _c("v-data-table", {
                                ref: "cartTable",
                                staticClass: "cart-table unbox_scrollbar",
                                attrs: {
                                  "items-per-page": _vm.checkoutCart.length,
                                  "item-key": "productDisplaySku",
                                  items: _vm.checkoutCart,
                                  "hide-default-footer": "",
                                  "no-data-text": _vm.$t("label.cartEmpty"),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "full-width px-0 d-table",
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "my-2 mx-1 rounded-lg",
                                                    attrs: {
                                                      color: "transparent",
                                                      tile: "",
                                                      elevation: "2",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "full-width d-table",
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "cart-productColumn image cursor-pointer v-align-top",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goToProductDetailPage(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "cart-productImage ma-2",
                                                                attrs: {
                                                                  src:
                                                                    _vm.shared
                                                                      .MEDIA_SERVER_URL +
                                                                    "/products/variants/" +
                                                                    item.productDisplaySku +
                                                                    ".png",
                                                                  alt:
                                                                    item.productName +
                                                                    " " +
                                                                    item.variation,
                                                                  title:
                                                                    item.productName +
                                                                    " " +
                                                                    item.variation,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "cart-productColumn",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "py-4 px-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.goToProductDetailPage(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold text-sm-body-1 text-body-2 mb-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.productName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-sm-body-1 text-body-2 mb-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.variation
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$shared.formatCurrency(
                                                                                item.unitPrice
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                      attrs: {
                                                                        align:
                                                                          "center",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "auto",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-sm-body-1 text-body-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "label.quantity"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "auto",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "120px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "cart-quantity",
                                                                                  attrs:
                                                                                    {
                                                                                      readonly: true,
                                                                                      "hide-details":
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      type: "number",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        item.quantity,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "quantity",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "item.quantity",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  item.insurance
                                                                    ? _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mb-2",
                                                                                      attrs:
                                                                                        {
                                                                                          "no-gutters":
                                                                                            "",
                                                                                          align:
                                                                                            "top",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              cols: "auto",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-checkbox",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mt-0",
                                                                                              attrs:
                                                                                                {
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    item.requiredInsurance,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        item,
                                                                                                        "requiredInsurance",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "item.requiredInsurance",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        [
                                                                                          _c(
                                                                                            "p",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mb-0",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "label.insurancePlan",
                                                                                                      [
                                                                                                        _vm.$shared.formatCurrency(
                                                                                                          item
                                                                                                            .insurance
                                                                                                            .unit_price
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "link-text cursor-pointer",
                                                                                                  on: {
                                                                                                    click:
                                                                                                      _vm.openProtectionPlanPage,
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "label.tncsApply"
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "stroke--link_text",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          small:
                                                                                                            "",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          textContent:
                                                                                                            _vm._s(
                                                                                                              "$exclamationMarkIcon"
                                                                                                            ),
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  item.requiredInsurance
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$shared.formatCurrency(
                                                                                                    item
                                                                                                      .insurance
                                                                                                      .unit_price
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                "v-row",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mt-2",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      align:
                                                                                                        "center",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-col",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          sm: "auto",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-sm-body-1 text-body-2",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm.$t(
                                                                                                                "label.quantity"
                                                                                                              )
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-col",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          sm: "auto",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              width:
                                                                                                                "120px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-text-field",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "cart-quantity",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  readonly: true,
                                                                                                                  "hide-details":
                                                                                                                    "",
                                                                                                                  outlined:
                                                                                                                    "",
                                                                                                                  dense:
                                                                                                                    "",
                                                                                                                  type: "number",
                                                                                                                },
                                                                                                              model:
                                                                                                                {
                                                                                                                  value:
                                                                                                                    item
                                                                                                                      .insurance
                                                                                                                      .quantity,
                                                                                                                  callback:
                                                                                                                    function (
                                                                                                                      $$v
                                                                                                                    ) {
                                                                                                                      _vm.$set(
                                                                                                                        item.insurance,
                                                                                                                        "quantity",
                                                                                                                        _vm._n(
                                                                                                                          $$v
                                                                                                                        )
                                                                                                                      )
                                                                                                                    },
                                                                                                                  expression:
                                                                                                                    "item.insurance.quantity",
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "5", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-6 rounded-lg",
                          attrs: {
                            color: "transparent",
                            tile: "",
                            elevation: "5",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                justify: "space-between",
                                "no-gutters": "",
                              },
                            },
                            [
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.tax")))]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "unbox_black--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$shared.formatCurrency(0)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", {
                                staticClass: "ma-0 pa-0 mb-2",
                                attrs: { cols: "12" },
                              }),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold unbox_black--text text-body-1",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.total")))]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold unbox_red--text text-body-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$shared.formatCurrency(
                                            _vm.calculateTotalCartAmount(
                                              _vm.checkoutCart
                                            )
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm.noFullEnterCheckoutForm
                            ? _c(
                                "div",
                                { staticClass: "red--text text-body-2 my-4" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("label.pleaseSelectCheckout"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "rounded-lg font-weight-bold text-body-1 py-6 white--text",
                              attrs: { block: "", color: "unbox_red" },
                              on: { click: _vm.placePreOrder },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.placeOrder")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.changeAddressDialogShow,
          title: _vm.$t("action.changeAddress"),
          closeAction: function () {
            return (_vm.changeAddressDialogShow = !_vm.changeAddressDialogShow)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "changeAddressFrom-tab" },
                  [
                    _c("app-change-address", {
                      attrs: {
                        selectedAddressId: _vm.selectedAddressId,
                        updateSelectedAddress: _vm.updateSelectedAddress,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }